import defaults from "lodash/defaults";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";
import {
  type GetIncidentsParams,
  GetIncidentsSort,
  type IncidentCategory,
} from "../../../../.rest-hooks/types";
import type { IncidentStatus } from "../../../../.rest-hooks/types/incidents.yml";
import { ACTIVE_STATUSES } from "../../../config/incidentStatus";
import {
  getParamsFromPageQuery,
  getQueryFromPageQuery,
  getStringArrayFromQuery,
  getStringFromQuery,
  type PageQuery,
  type ParsedUrlQueryValue,
  pluckPageQueryFromQuery,
} from "./pageQuery";

const GetIncidentsSortValues = Object.values(GetIncidentsSort);

export interface IncidentSearchQuery extends Required<PageQuery> {
  categories: IncidentCategory[];
  sort: GetIncidentsSort;
  statuses: readonly IncidentStatus[];
  term: string;
}

export const defaultIncidentSearchQuery: IncidentSearchQuery = {
  // NOTE: This is an empty/bush-grass-all default, but we default to "any" for the COP view elsewhere
  categories: [] as IncidentCategory[],
  page: 1,
  perPage: 10,
  sort: GetIncidentsSort.alertLevelDesc,
  term: "",
  statuses: ACTIVE_STATUSES,
} as const;

export const isIncidentSortOrder = (
  value: string,
): value is GetIncidentsSort => {
  return GetIncidentsSortValues.includes(value as GetIncidentsSort);
};

export const getIncidentSortOrderFromQuery = (
  value: ParsedUrlQueryValue,
): GetIncidentsSort | undefined => {
  const sortOrder = getStringFromQuery(value);
  if (!sortOrder) {
    return undefined;
  }

  if (isIncidentSortOrder(sortOrder)) {
    return sortOrder;
  }

  return undefined;
};

export const pluckIncidentSearchQueryFromQuery = (
  query: ParsedUrlQuery,
): IncidentSearchQuery => {
  const pageQuery = pluckPageQueryFromQuery(query);

  return defaults<
    Record<string, never>,
    PageQuery,
    Partial<IncidentSearchQuery>,
    IncidentSearchQuery
  >(
    {},
    pageQuery,
    {
      categories: getStringArrayFromQuery(
        query.categories,
      ) as IncidentCategory[],
      sort: getIncidentSortOrderFromQuery(query.sort),
      statuses: getStringArrayFromQuery(query.statuses) as IncidentStatus[],
      term: getStringFromQuery(query.term),
    },
    defaultIncidentSearchQuery,
  );
};

export const getParamsFromIncidentSearchQuery = ({
  categories,
  page,
  perPage,
  sort,
  statuses,
  term,
}: IncidentSearchQuery): GetIncidentsParams => {
  const pageParams = getParamsFromPageQuery({ page, perPage });

  return {
    categories,
    sort,
    ...pageParams,
    ...(term && { term }),
    ...(statuses.length && { statuses: statuses.join(",") }),
  };
};

export const isIncidentSearchQueryEmpty = (
  searchQuery: IncidentSearchQuery,
): boolean => {
  return isEqual(
    omit(defaultIncidentSearchQuery, "page", "perPage"),
    omit(searchQuery, "page", "perPage"),
  );
};

export const getQueryFromIncidentSearchQuery = ({
  categories,
  page,
  perPage,
  sort,
  statuses,
  term,
}: IncidentSearchQuery): ParsedUrlQueryInput => {
  return {
    ...getQueryFromPageQuery({ page, perPage }),
    ...(term && { term }),
    // Don't clutter the user's URL query unless the params diverge from the
    // defaults
    ...(categories &&
      categories !== defaultIncidentSearchQuery.categories && { categories }),
    ...(sort && sort !== defaultIncidentSearchQuery.sort && { sort }),
    ...(statuses.length &&
      !isEqual(statuses, defaultIncidentSearchQuery.statuses) && {
        statuses,
      }),
  };
};
