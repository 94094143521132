import {
  Button,
  Close,
  fadeInStyles,
  IconButton,
  media,
  onPromise,
  Search,
  TextInput,
} from "@app/design-system";
import fadeUpStyles from "@app/design-system/src/lib/styled/fadeUpStyles";
import { useDialog } from "@react-aria/dialog";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import styled, { useTheme } from "styled-components";
import { SEARCH_RESULTS_TERM_ID } from "../IncidentsList/IncidentsList";
import {
  defaultIncidentSearchQuery,
  getQueryFromIncidentSearchQuery,
} from "../SearchResults/incidentSearchQuery";

const StyledSearchModalDialog = styled.div`
  ${fadeUpStyles}
  display: grid;
  width: 100%;
  gap: 2rem;
  color: ${(p) => p.theme.colors.neutrals.textInverse};

  @media ${media.lg} {
    padding: 1rem;
  }
`;

const StyledClose = styled.div`
  position: fixed;
  inset: 1.5rem 1.5rem auto auto;

  ${fadeInStyles}
`;

const StyledContent = styled.div`
  display: grid;
  gap: 0.5rem;
  text-align: center;

  ${fadeInStyles}
`;

const StyledTitle = styled.h1`
  ${(p) => p.theme.typography.variants.h1}
`;

const StyledLabel = styled.label`
  ${(p) => p.theme.typography.variants.bodyLg}
`;

const StyledForm = styled.form`
  display: grid;
  align-items: start;
  gap: 1rem;

  @media ${media.lg} {
    gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr) auto;
  }
`;

const StyledField = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface SearchModalDialogFormValues {
  term: string;
}

const defaultSearchModalDialogFormValues: SearchModalDialogFormValues = {
  term: "",
};

const SEARCH_MODAL_TERM_INPUT_ID = "searchModalTerm";

interface SearchModalDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const SearchModalDialog = ({ isOpen, onClose }: SearchModalDialogProps) => {
  const theme = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    register,
  } = useForm<SearchModalDialogFormValues>({
    defaultValues: defaultSearchModalDialogFormValues,
  });

  const { dialogProps, titleProps } = useDialog({}, modalRef);

  const onSubmit = async (values: SearchModalDialogFormValues) => {
    const pathname = window.location.href.includes("cop")
      ? "/cop/incidents"
      : "/incidents";

    const query = getQueryFromIncidentSearchQuery({
      ...defaultIncidentSearchQuery,
      term: values.term,
    });

    await router.push({
      pathname,
      query,
    });

    onClose();
  };

  useEffect(() => {
    if (!isSubmitSuccessful && isOpen) {
      const modalTermInput = document.querySelector<HTMLInputElement>(
        `#${SEARCH_MODAL_TERM_INPUT_ID}`,
      );

      modalTermInput?.focus();
    }
  }, [isOpen, isSubmitSuccessful]);

  // When this component has unmounted, focus the search term input if the user
  // submitted a search request.
  // This must happen inside a cleanup effect, as otherwise the FocusScope will
  // prevent triggering focus onto any elements outside the modal.
  useEffect(() => {
    return () => {
      if (!isSubmitSuccessful) {
        return;
      }

      const termInput = document.querySelector<HTMLInputElement>(
        `#${SEARCH_RESULTS_TERM_ID}`,
      );

      termInput?.focus();
    };
  }, [isSubmitSuccessful]);

  return (
    <CSSTransition
      appear
      in={isOpen}
      nodeRef={modalRef}
      timeout={theme.anim.duration.sm}
    >
      <>
        <StyledSearchModalDialog {...dialogProps} ref={modalRef}>
          <StyledContent>
            <StyledTitle {...titleProps}>Search Athena</StyledTitle>
            <StyledLabel htmlFor={SEARCH_MODAL_TERM_INPUT_ID}>
              Search for an incident via incident name or LGA
            </StyledLabel>
          </StyledContent>
          <StyledForm onSubmit={onPromise(handleSubmit(onSubmit))}>
            <StyledField>
              <TextInput
                {...register("term")}
                id={SEARCH_MODAL_TERM_INPUT_ID}
                iconStart={Search}
                placeholder="Incident name or LGA"
              />
            </StyledField>
            <Button disabled={isSubmitting} type="submit">
              Search
            </Button>
          </StyledForm>
        </StyledSearchModalDialog>
        <StyledClose>
          <IconButton
            icon={Close}
            label="Close"
            onClick={onClose}
            variant="inverse"
          />
        </StyledClose>
      </>
    </CSSTransition>
  );
};

export default SearchModalDialog;
