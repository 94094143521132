import getConfig from "next/config";
import { IncidentStatus } from "../../.rest-hooks/types/incidents.yml";

const incidentStatusLabels: Record<IncidentStatus, string> = {
  [IncidentStatus.BEINGCONTROLLED]: "Being Controlled",
  [IncidentStatus.CONTAINED]: "Contained",
  [IncidentStatus.GOING]: "Going",
  [IncidentStatus.OUT]: "Out",
  [IncidentStatus.OUTAMALGAMATED]: "Out",
  [IncidentStatus.PATROL]: "Patrol",
  [IncidentStatus.PLANNED]: "Planned",
  [IncidentStatus.RESPONDING]: "Responding",
};

const getEnvironment = () =>
  getConfig()?.publicRuntimeConfig?.ENVIRONMENT || "dev";

export const formatIncidentStatus = (status: IncidentStatus) => {
  const label = incidentStatusLabels[status];
  if (label) {
    return label;
  }

  if (status) {
    return status;
  }

  return "—";
};

export const getRFSUrlEnvironmentString = () => {
  const env = getEnvironment();

  if (env === "prod") {
    return "";
  }
  return `${env}.`;
};

export const ICON_URL = `https://icon.${getRFSUrlEnvironmentString()}rfs.nsw.gov.au/FireIncident/FireIncident/Review/`;

export enum SimulationType {
  GENERAL = "GENERAL",
}

export const FIRE_WEATHER_URL = `https://shared-athena.${getRFSUrlEnvironmentString()}apps.rfs.nsw.gov.au/geoserver/fireweather/ows`;

export const HERE_MAPS_URL = "https://geocode.search.hereapi.com/v1/geocode";
