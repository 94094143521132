import {
  AirTraffic,
  AnchorButton,
  Civilian,
  Emergency,
  FieldGrid,
  StaticIconWrapper,
  Text,
  focusStyles,
} from "@app/design-system";
import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import type { Incident } from "../../../../.rest-hooks/types";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import QueryParams from "../../../config/queryParams";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import TaskLink from "../../util/TaskLink/TaskLink";
import AlertLevelIcon from "../AlertLevelIcon/AlertLevelIcon";
import IncidentNotificationToggleButton from "../IncidentNotificationToggleButton/IncidentNotificationToggleButton";
import IncidentPriorityIndicator from "../IncidentPriorityIndicator/IncidentPriorityIndicator";
import IncidentOverflowMenu from "../IncidentsDataTable/IncidentOverflowMenu";

const StyledIncidentTile = styled.div`
  display: grid;
  padding: 0.75rem;
  gap: 1rem;
  color: ${(p) => p.theme.colors.neutrals.text};
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledMain = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledHeader = styled.div`
  display: grid;
  gap: 0.25rem;
`;

const StyledOverline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledTitle = styled.a`
  ${(p) => p.theme.typography.variants.subtitleMd}
  color: ${(p) => p.theme.colors.neutrals.text};
  word-break: break-word;

  &:is(a):hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledIcons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const StyledBody = styled.div`
  display: grid;
  gap: 1rem;
`;

export const StyledIconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: center;
`;

export const StyledIconGridItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
`;

interface IconGridItemProps {
  icon: ReactSVGComponent;
  value: number | null | undefined;
}

const IconGridItem = ({ icon, value }: IconGridItemProps) => {
  const formattedValue =
    typeof value === "number"
      ? new Intl.NumberFormat("en-AU", {}).format(value)
      : "—";

  return (
    <StyledIconGridItem>
      <StaticIconWrapper icon={icon} size="md" />
      <Text size="bodyDefault" variant="weak">
        {formattedValue}
      </Text>
    </StyledIconGridItem>
  );
};

interface IncidentTileProps extends React.AnchorHTMLAttributes<HTMLDivElement> {
  incident: Incident;
  isCopView?: boolean;
}

const IncidentTile: ForwardRefRenderFunction<
  HTMLDivElement,
  IncidentTileProps
> = ({ incident, isCopView = false, ...props }: IncidentTileProps, ref) => {
  let title = (
    <StyledTitle as={isCopView ? "div" : "a"}>
      {incident.attributes.name || <>&mdash;</>}
    </StyledTitle>
  );

  // COP users do not have access to the incident map. Therefore do not wrap
  // titles in link unless this is not COP view.
  if (!isCopView) {
    title = (
      <TaskLink
        as={`/incidents/${incident.id}`}
        href={{
          pathname: "/incidents/[id]",
          query: { [QueryParams.INCIDENT_ID]: incident.id },
        }}
        legacyBehavior
      >
        {title}
      </TaskLink>
    );
  }

  return (
    <StyledIncidentTile {...props} ref={ref}>
      <StyledMain>
        <StyledHeader>
          <StyledOverline>
            <StyledIcons>
              <AlertLevelIcon
                size="xl"
                alertLevel={getAlertLevelFromIncident(incident)}
              />
              <IncidentPriorityIndicator
                errorVariant="inline"
                incident={incident}
              />
            </StyledIcons>
            <StyledActions>
              {!isCopView && (
                <>
                  <IncidentNotificationToggleButton incidentId={incident.id} />
                  <IncidentOverflowMenu
                    incidentId={incident.id}
                    name={incident.attributes.name}
                    variant="ghost"
                  />
                </>
              )}
            </StyledActions>
          </StyledOverline>
          {title}
        </StyledHeader>
        <StyledBody>
          <FieldGrid>
            <FieldGrid.Item label="Status:">
              {incident.attributes.status || <>&mdash;</>}
            </FieldGrid.Item>
            <FieldGrid.Item label="LGA:">
              {incident.attributes.lga || <>&mdash;</>}
            </FieldGrid.Item>
            <FieldGrid.Item label="Class:">
              {incident.attributes.fireClass || <>&mdash;</>}
            </FieldGrid.Item>
            <FieldGrid.Item label="Type:">
              {incident.attributes.types.length
                ? incident.attributes.types.join(", ")
                : "—"}
            </FieldGrid.Item>
            <FieldGrid.Item label="Size:">
              {`${incident.attributes.burntArea} ha` || <>&mdash;</>} ha
            </FieldGrid.Item>
            <FieldGrid.Item label="Last updated:">
              {incident.attributes.updatedAt ? (
                getFormattedDateAndTime(incident.attributes.updatedAt * 1000)
              ) : (
                <>&mdash;</>
              )}
            </FieldGrid.Item>
          </FieldGrid>
          <StyledIconGrid>
            <IconGridItem
              icon={Civilian}
              value={incident.attributes.numFirefighter}
            />
            <IconGridItem
              icon={Emergency}
              value={incident.attributes.numVehicles}
            />
            <IconGridItem
              icon={AirTraffic}
              value={incident.attributes.numAircraft}
            />
          </StyledIconGrid>
        </StyledBody>
      </StyledMain>

      {!isCopView && (
        <TaskLink
          as={`/incidents/${incident.id}`}
          href={{
            pathname: "/incidents/[id]",
            query: { [QueryParams.INCIDENT_ID]: incident.id },
          }}
          legacyBehavior
        >
          <AnchorButton size="sm" variant="secondary">
            View incident
          </AnchorButton>
        </TaskLink>
      )}
    </StyledIncidentTile>
  );
};

export default forwardRef(IncidentTile);
