import { DataTable } from "@app/design-system";
import type { ColumnSort } from "@tanstack/react-table";
import type { GetIncidentsSort, Incident } from "../../../../.rest-hooks/types";
import { incidentsSortingRules } from "../../../config/incidentsSortingRules";
import QueryParams from "../../../config/queryParams";
import { useSorting } from "../../../hooks/useSorting";
import TaskLink from "../../util/TaskLink/TaskLink";
import { getColumns, type IncidentRow } from "./columns";

interface IncidentsDataTableProps {
  incidents: Incident[];
  isCopView?: boolean;
  onSortChange: (sort?: GetIncidentsSort) => void;
  page: number;
  perPage: number;
  sort: GetIncidentsSort;
}

const IncidentsDataTable = ({
  incidents,
  isCopView = false,
  onSortChange,
  page,
  perPage,
  sort,
}: IncidentsDataTableProps) => {
  const tableData: IncidentRow[] =
    incidents?.map((incident: Incident) => ({
      id: incident.id,
      alertLevel: incident.attributes.alertLevel,
      status: incident.attributes.status,
      name: incident.attributes.name,
      lga: incident.attributes.lga,
      fireClass: incident.attributes.fireClass,
      types: incident.attributes.types,
      burntArea: incident.attributes.burntArea,
      numVehicles: incident.attributes.numVehicles,
      numAircraft: incident.attributes.numAircraft,
      numFirefighter: incident.attributes.numFirefighter,
      updatedAt: incident.attributes.updatedAt,
      isCopView,
    })) ?? [];

  const sorting = useSorting({ sort, sortingRules: incidentsSortingRules });

  const onSortingChange = (columnSort?: ColumnSort) => {
    if (!columnSort) {
      onSortChange();
      return;
    }
    onSortChange(
      `${columnSort.id}${columnSort.desc ? "Desc" : "Asc"}` as GetIncidentsSort,
    );
  };

  return (
    <DataTable
      clickableRows={!isCopView}
      columns={getColumns({ isCopView })}
      data={tableData}
      getRowId={(row) => row.id}
      onSortingChange={onSortingChange}
      key={sort}
      page={page}
      perPage={perPage}
      renderRow={(row, rowElement) =>
        isCopView ? (
          rowElement
        ) : (
          <TaskLink
            as={`/incidents/${row.id}`}
            href={{
              pathname: "/incidents/[id]",
              query: {
                [QueryParams.INCIDENT_ID]: row.id,
              },
            }}
          >
            {rowElement}
          </TaskLink>
        )
      }
      sorting={sorting}
      data-testid="incidents-table"
    />
  );
};

export default IncidentsDataTable;
