import {
  Pagination,
  SkeletonDataTable,
  Spinner,
  media,
  type PageStateChangeHandler,
} from "@app/design-system";
import React, { useEffect } from "react";
import styled from "styled-components";
import type { ResponseManyMeta } from "../../../../.rest-hooks/types/common.yml";
import RecordCountIndicator, {
  SkeletonRecordCountIndicator,
} from "./RecordCountIndicator";

export const StyledTableLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const StyledHeader = styled.div`
  display: grid;
  align-items: start;
  gap: 0.5rem;

  @media ${media.md} {
    grid-template-columns: minmax(0, 1fr) auto;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.h2}
`;

const StyledSubtitle = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const StyledHeading = styled.div`
  display: grid;
  gap: 0.125rem;
`;

export const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledSubheader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 0 1rem;

  @media ${media.lg} {
    padding: 0;
    flex-direction: row;
    gap: 1.5rem;
    align-items: end;
  }
`;

export const StyledCount = styled.div`
  @media ${media.lg} {
    margin-right: auto;
  }
`;

export const StyledFilters = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  overflow-x: auto;
  margin: -1rem;
  padding: 1rem;

  @media ${media.md} {
    gap: 1rem;
    margin: 0;
    padding: 0;
    overflow-x: initial;
  }
`;

export const StyledSearch = styled.div`
  width: 100%;

  @media ${media.md} {
    width: 20rem;
  }

  @media ${media.lg} {
    width: 16rem;
  }
`;

const StyledTable = styled.div`
  position: relative;
  margin: -1rem -1rem;
  padding: 0.5rem 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  border-top: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

  @media ${media.lg} {
    margin: 0;
    padding: 0;
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border-top: none;
    border-bottom: none;
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }
`;

export const StyledRefine = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @media ${media.lg} {
    &:not(:first-child) {
      padding-left: 0.5rem;
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 0 0;

  @media ${media.lg} {
    padding: 0.5rem 0.75rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }
`;

interface TableLayoutProps {
  actions?: React.ReactNode;
  children: React.ReactNode;
  chips?: React.ReactNode;
  emptyState: React.ReactNode;
  errorState: React.ReactNode;
  isError: React.ReactNode;
  isFetching?: boolean;
  isLoading?: boolean;
  meta: ResponseManyMeta | undefined;
  onPageStateChange: PageStateChangeHandler;
  page: number;
  pageLength: number;
  perPage: number;
  refineFilter?: React.ReactNode;
  search?: React.ReactNode;
  title?: React.ReactNode;
  "data-testid"?: string;
}

const TableLayout = ({
  actions,
  children,
  chips,
  emptyState,
  errorState,
  isError,
  isFetching,
  isLoading,
  meta,
  onPageStateChange,
  page,
  pageLength,
  perPage,
  refineFilter,
  search,
  title,
  "data-testid": dataTestId,
}: TableLayoutProps) => {
  const { totalPages = 0, totalRecords = 0 } = meta ?? {};

  useEffect(() => {
    if (page > 1 && !pageLength && !isFetching && !isLoading) {
      onPageStateChange({
        page: totalPages || 1,
        perPage,
      });
    }
  }, [
    isFetching,
    isLoading,
    onPageStateChange,
    page,
    pageLength,
    perPage,
    totalPages,
  ]);

  useEffect(() => {
    if (page < 1) {
      onPageStateChange({
        page: 1,
        perPage,
      });
    }
  }, [onPageStateChange, page, perPage]);

  const isEmpty = !pageLength;

  let content: React.ReactNode;

  if (isLoading) {
    content = <SkeletonDataTable perPage={perPage} />;
  } else if (isError) {
    content = errorState;
  } else if (isEmpty) {
    content = emptyState;
  } else {
    content = children;
  }

  const hasSubheader = !!search || !!chips || !!refineFilter;

  return (
    <StyledTableLayout>
      <StyledHeader>
        <StyledHeading>
          <StyledTitle>
            {title}
            {isFetching && !!title && <Spinner size="sm" />}
          </StyledTitle>
          <StyledSubtitle>
            {isLoading ? (
              <SkeletonRecordCountIndicator size="bodyLg" />
            ) : (
              <>
                <RecordCountIndicator
                  page={page}
                  pageLength={pageLength}
                  perPage={perPage}
                  variant="weak"
                  totalRecords={totalRecords}
                  isEmpty={isEmpty}
                  size="bodyLg"
                />
                {isFetching && !title && <Spinner size="sm" />}
              </>
            )}
          </StyledSubtitle>
        </StyledHeading>
        {!!actions && <StyledActions>{actions}</StyledActions>}
      </StyledHeader>
      <StyledContent>
        {hasSubheader && (
          <StyledSubheader>
            <StyledFilters
              data-testid={dataTestId && `${dataTestId}-chip-list`}
            >
              {!!search && <StyledSearch>{search}</StyledSearch>}
              {chips}
              {!!refineFilter && <StyledRefine>{refineFilter}</StyledRefine>}
            </StyledFilters>
          </StyledSubheader>
        )}
        <StyledTable>{content}</StyledTable>
      </StyledContent>
      <StyledFooter>
        {!isEmpty && (
          <>
            <StyledCount>
              {isLoading ? (
                <SkeletonRecordCountIndicator size="bodyLg" />
              ) : (
                <RecordCountIndicator
                  page={page}
                  pageLength={pageLength}
                  perPage={perPage}
                  size="bodyLg"
                  totalRecords={totalRecords}
                  isEmpty={isEmpty}
                />
              )}
            </StyledCount>
            <Pagination
              onPageStateChange={onPageStateChange}
              page={page}
              perPage={perPage}
              totalPages={totalPages}
            />
          </>
        )}
      </StyledFooter>
    </StyledTableLayout>
  );
};

export default TableLayout;
