import Link, { type LinkProps } from "next/link";
import { type NextRouter, useRouter } from "next/router";

type IsActiveFn = (router: NextRouter) => boolean;

export interface NavLinkRenderProps {
  isActive: boolean;
}

export type NavLinkProps = LinkProps & {
  exact?: boolean;
  isActive?: IsActiveFn;
  render: (props: NavLinkRenderProps) => React.ReactNode;
};

export const getPathFromPathname = (pathname: string) => pathname.split("?")[0];

const isHrefActive = (href: string, pathname: string, exact?: boolean) => {
  const path = getPathFromPathname(pathname);
  return exact ? path === href : path.startsWith(href ?? "");
};

interface UseIsNavLinkActiveParams {
  exact?: boolean;
  as: LinkProps["as"];
  href: LinkProps["href"];
  isActive?: IsActiveFn;
}

interface IsNavLinkActiveParams {
  exact?: boolean;
  as: LinkProps["as"];
  href: LinkProps["href"];
  router: NextRouter;
}

export const isNavLinkActive = ({
  exact,
  router,
  ...props
}: IsNavLinkActiveParams) => {
  const { asPath, pathname } = router;

  const as = typeof props.as === "string" ? props.as : props.as?.href;
  const href = typeof props.href === "string" ? props.href : props.href.href;

  const isActive = as
    ? isHrefActive(as, asPath, exact)
    : isHrefActive(href ?? "", pathname, exact);

  return isActive;
};

export const useIsNavLinkActive = ({
  as,
  exact,
  href,
  isActive,
}: UseIsNavLinkActiveParams) => {
  const router = useRouter();

  if (isActive) {
    return isActive(router);
  }

  return isNavLinkActive({ as, exact, href, router });
};

const NavLink = ({
  exact,
  isActive: isActiveFn,
  passHref = true,
  render,
  ...props
}: NavLinkProps) => {
  const { as, href } = props;
  const isActive = useIsNavLinkActive({
    as,
    exact,
    href,
    isActive: isActiveFn,
  });

  return (
    <Link passHref={passHref} {...props}>
      {render({ isActive })}
    </Link>
  );
};

export default NavLink;
