import {
  NavDrawerToggleButton,
  NavHeader,
  useSyncCSSProperty,
} from "@app/design-system";
import useDimensions from "react-cool-dimensions";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import NavHeaderBackButton from "../NavHeaderBackButton/NavHeaderBackButton";
import NotificationButton from "../NotificationButton/NotificationButton";
import SearchModalNavHeaderButton from "../SearchModal/SearchModalNavHeaderButton";

export const APP_NAV_HEADER_HEIGHT_PROPERTY = "--app-nav-header-height";

interface AppNavHeaderProps {
  actions?: React.ReactNode;
  parentHref?: string;
  tabList?: React.ReactNode;
  title: React.ReactNode | string;
}

const AppNavHeader = ({
  actions,
  parentHref,
  tabList,
  title,
}: AppNavHeaderProps) => {
  const { observe: headerRef, height } = useDimensions();

  const { hasPrivilege: hasAviationPrivilege } = useHasPrivilege({
    requestedPermissions: ["aviation-safety:read"],
  });

  useSyncCSSProperty({
    name: APP_NAV_HEADER_HEIGHT_PROPERTY,
    value: `${height}px`,
  });

  const hasBackButton = !!parentHref;

  return (
    <NavHeader
      actions={
        <>
          {actions}
          {hasAviationPrivilege && (
            <NotificationButton data-testid="notification-button" />
          )}
          {!hasBackButton && <SearchModalNavHeaderButton />}
        </>
      }
      nav={
        hasBackButton ? (
          <NavHeaderBackButton parentHref={parentHref} />
        ) : (
          <NavDrawerToggleButton data-testid="nav-drawer-toggle" />
        )
      }
      ref={headerRef}
      tabList={tabList}
      title={title}
    />
  );
};

export default AppNavHeader;
