import {
  Drawer,
  NavHeaderButton,
  NotificationBellFilled,
  NotificationBellIndicator,
  Popover,
  useIsMinWidth,
} from "@app/design-system";
import { useButton } from "@react-aria/button";
import { useOverlayTrigger } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useRef } from "react";
import makeTestId from "../../../utils/makeTestId";
import NotificationDialog from "./NotificationDialog";
import { useNotificationsUserInfinite } from "./hooks";

export interface NotificationButtonProps {
  "data-testid"?: string;
}

const NotificationButton = ({
  "data-testid": dataTestId,
}: NotificationButtonProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const triggerState = useOverlayTriggerState({});
  const { overlayProps, triggerProps } = useOverlayTrigger(
    {
      type: "dialog",
    },
    triggerState,
    triggerRef,
  );
  const { buttonProps } = useButton(triggerProps, triggerRef);
  const isTabletLandscapeAndAbove = useIsMinWidth("md");
  const { hasUnreadNotifications } = useNotificationsUserInfinite();

  return (
    <>
      <NavHeaderButton
        {...buttonProps}
        data-testid={makeTestId(dataTestId, "trigger")}
        ref={triggerRef}
        label="Notifications"
        icon={
          hasUnreadNotifications
            ? NotificationBellIndicator
            : NotificationBellFilled
        }
      />
      {isTabletLandscapeAndAbove ? (
        <Popover
          {...overlayProps}
          size="lg"
          state={triggerState}
          triggerRef={triggerRef}
          data-testid={makeTestId(dataTestId, "popover")}
        >
          <NotificationDialog onClose={triggerState.close} />
        </Popover>
      ) : (
        <Drawer
          title="Notifications"
          overlayTriggerState={triggerState}
          overlayProps={overlayProps}
        >
          <NotificationDialog onClose={triggerState.close} />
        </Drawer>
      )}
    </>
  );
};

export default NotificationButton;
