import {
  AlertLevelAdvice,
  AlertLevelEmergencyWarning,
  AlertLevelNoAlert,
  AlertLevelOtherFire,
  AlertLevelPending,
  AlertLevelPlannedBurn,
  AlertLevelResponding,
  AlertLevelWatchAndAct,
} from "@app/design-system";
import type { Incident } from "../../.rest-hooks/types";
import type { IncidentAlertLevel } from "../../.rest-hooks/types/incidents.yml";

export type AlertLevelKeys = IncidentAlertLevel | "Pending" | "Responding";

export const AlertLevelIconMap: Record<AlertLevelKeys, ReactSVGComponent> = {
  Advice: AlertLevelAdvice,
  "Emergency Warning": AlertLevelEmergencyWarning,
  None: AlertLevelNoAlert,
  Other: AlertLevelOtherFire,
  Pending: AlertLevelPending,
  "Planned Burn": AlertLevelPlannedBurn,
  Responding: AlertLevelResponding,
  "Watch and Act": AlertLevelWatchAndAct,
} as const;

export const AlertLevelIconIdMap: Record<AlertLevelKeys, string> = {
  Advice: "alert-level-advice",
  "Emergency Warning": "alert-level-emergency-warning",
  None: "alert-level-no-alert",
  Other: "alert-level-other-fire",
  Pending: "alert-level-pending",
  "Planned Burn": "alert-level-planned-burn",
  Responding: "alert-level-responding",
  "Watch and Act": "alert-level-watch-and-act",
} as const;

export function getAlertLevelIcon(
  alertLevel?: AlertLevelKeys | null,
): ReactSVGComponent {
  if (!alertLevel) return AlertLevelIconMap.Pending;

  if (alertLevel in AlertLevelIconMap) {
    return AlertLevelIconMap[alertLevel];
  }

  return AlertLevelIconMap.Other;
}

export function getAlertLevelIconName(alertLevel?: AlertLevelKeys | null) {
  if (!alertLevel) return AlertLevelIconIdMap.Pending;

  if (alertLevel in AlertLevelIconIdMap) {
    return AlertLevelIconIdMap[alertLevel];
  }

  return AlertLevelIconIdMap.Other;
}

export function getAlertLevelLabel(alertLevel?: AlertLevelKeys | null) {
  if (!alertLevel) return "Pending";

  return alertLevel;
}

export function getAlertLevelFromIncident(incident: Incident): AlertLevelKeys {
  return incident.attributes.status === "RESPONDING"
    ? "Responding"
    : incident.attributes.alertLevel;
}
