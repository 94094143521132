/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetAggregatesIncidents200,
  GetAggregatesIncidentsParams,
  GetIncidents200,
  GetIncidentsId200,
  GetIncidentsIdPermissions200,
  GetIncidentsParams,
  PatchIncidentsId200,
  PutIncidentsIdReviewPredictions200,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";
import type { IncidentAttributesOptional } from "./types/incidents.yml";

/**
 * @summary Aggregate incident data points
 */
export const getAggregatesIncidents = (
  params?: GetAggregatesIncidentsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetAggregatesIncidents200>> => {
  return axios.get(`/aggregates/incidents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetAggregatesIncidentsQueryKey = (
  params?: GetAggregatesIncidentsParams,
) => {
  return [`/aggregates/incidents`, ...(params ? [params] : [])] as const;
};

export const getGetAggregatesIncidentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAggregatesIncidents>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetAggregatesIncidentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAggregatesIncidents>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAggregatesIncidentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAggregatesIncidents>>
  > = ({ signal }) =>
    getAggregatesIncidents(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAggregatesIncidents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAggregatesIncidentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAggregatesIncidents>>
>;
export type GetAggregatesIncidentsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Aggregate incident data points
 */
export const useGetAggregatesIncidents = <
  TData = Awaited<ReturnType<typeof getAggregatesIncidents>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetAggregatesIncidentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAggregatesIncidents>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAggregatesIncidentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of incidents
 */
export const getIncidents = (
  params?: GetIncidentsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidents200>> => {
  return axios.get(`/incidents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentsQueryKey = (params?: GetIncidentsParams) => {
  return [`/incidents`, ...(params ? [params] : [])] as const;
};

export const getGetIncidentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidents>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getIncidents>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIncidentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidents>>> = ({
    signal,
  }) => getIncidents(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidents>>
>;
export type GetIncidentsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get a list of incidents
 */
export const useGetIncidents = <
  TData = Awaited<ReturnType<typeof getIncidents>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getIncidents>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get an incident
 */
export const getIncidentsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsId200>> => {
  return axios.get(`/incidents/${id}`, options);
};

export const getGetIncidentsIdQueryKey = (id: string) => {
  return [`/incidents/${id}`] as const;
};

export const getGetIncidentsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getIncidentsId>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIncidentsIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentsId>>> = ({
    signal,
  }) => getIncidentsId(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsId>>
>;
export type GetIncidentsIdQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get an incident
 */
export const useGetIncidentsId = <
  TData = Awaited<ReturnType<typeof getIncidentsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getIncidentsId>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Partial update of an incident
 */
export const patchIncidentsId = (
  id: string,
  incidentAttributesOptional: IncidentAttributesOptional,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PatchIncidentsId200>> => {
  return axios.patch(`/incidents/${id}`, incidentAttributesOptional, options);
};

export const getPatchIncidentsIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchIncidentsId>>,
    TError,
    { id: string; data: IncidentAttributesOptional },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchIncidentsId>>,
  TError,
  { id: string; data: IncidentAttributesOptional },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchIncidentsId>>,
    { id: string; data: IncidentAttributesOptional }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchIncidentsId(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchIncidentsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchIncidentsId>>
>;
export type PatchIncidentsIdMutationBody = IncidentAttributesOptional;
export type PatchIncidentsIdMutationError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Partial update of an incident
 */
export const usePatchIncidentsId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchIncidentsId>>,
    TError,
    { id: string; data: IncidentAttributesOptional },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPatchIncidentsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get which features the current user has permission to access for this incident.
 * @summary Get user permissions for incident
 */
export const getIncidentsIdPermissions = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsIdPermissions200>> => {
  return axios.get(`/incidents/${id}/permissions`, options);
};

export const getGetIncidentsIdPermissionsQueryKey = (id: string) => {
  return [`/incidents/${id}/permissions`] as const;
};

export const getGetIncidentsIdPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdPermissions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentsIdPermissionsQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsIdPermissions>>
  > = ({ signal }) =>
    getIncidentsIdPermissions(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsIdPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentsIdPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsIdPermissions>>
>;
export type GetIncidentsIdPermissionsQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get user permissions for incident
 */
export const useGetIncidentsIdPermissions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdPermissions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentsIdPermissionsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Sets flag `predictionReviewRequired` to `false`.
 * @summary Review predictions
 */
export const putIncidentsIdReviewPredictions = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutIncidentsIdReviewPredictions200>> => {
  return axios.put(`/incidents/${id}/reviewPredictions`, undefined, options);
};

export const getPutIncidentsIdReviewPredictionsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putIncidentsIdReviewPredictions>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putIncidentsIdReviewPredictions>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putIncidentsIdReviewPredictions>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return putIncidentsIdReviewPredictions(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutIncidentsIdReviewPredictionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof putIncidentsIdReviewPredictions>>
>;

export type PutIncidentsIdReviewPredictionsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Review predictions
 */
export const usePutIncidentsIdReviewPredictions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putIncidentsIdReviewPredictions>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPutIncidentsIdReviewPredictionsMutationOptions(options);

  return useMutation(mutationOptions);
};
