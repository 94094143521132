import { Tooltip } from "@app/design-system";
import { useButton } from "@react-aria/button";
import { useRef } from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  padding: 6px;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  &:hover {
    background-color: ${(p) => p.theme.colors.neutrals.background};
    padding: 5px;
    border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};
  }
`;

const StyledCenter = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.primary.icon};
`;

const UnreadIndicator = ({ onClick }: { onClick: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps } = useButton(
    { elementType: "div", onPress: onClick },
    ref,
  );

  return (
    <Tooltip message="Mark as read" placement="left" interactive>
      <StyledButton
        {...buttonProps}
        ref={ref}
        data-testid="NotificationRow-UnreadIndicator"
      >
        <StyledCenter />
      </StyledButton>
    </Tooltip>
  );
};

export default UnreadIndicator;
