import Link, { type LinkProps } from "next/link";
import type { UrlObject } from "url";
import QueryParams from "../../../config/queryParams";

interface TaskLinkProps extends Omit<LinkProps, "href"> {
  children?: React.ReactNode;
  // `href` must be provided in object form. Any dynamic path segments must also
  // be provided in the `query` property of the `href` object.
  href: UrlObject;
}

/**
 * Query from UrlObject can either be a string e.g. `myParam=x&someOther=y`
 * or an object-like ParsedUrlQueryInput. This function normalizes it to a
 * spreadable object so that we may augment the query with our extra parameter.
 */
const getQueryAsObject = (query: UrlObject["query"]) => {
  if (typeof query === "string") {
    const searchParams = new URLSearchParams(query);
    return Object.fromEntries(searchParams);
  }

  if (query) {
    return query;
  }

  return {};
};

const TaskLink = ({
  children,
  as,
  href,
  passHref = true,
  ...props
}: TaskLinkProps) => {
  const query = {
    ...getQueryAsObject(href.query),
    [QueryParams.HAS_BACK_STACK]: true,
  };

  return (
    <Link
      {...props}
      as={as ?? href}
      href={{
        ...href,
        query,
      }}
      passHref={passHref}
    >
      {children}
    </Link>
  );
};

export default TaskLink;
