import isEqual from "lodash/isEqual";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type {
  GetIncidentsParams,
  IncidentCategory,
} from "../../../../.rest-hooks/types";
import type { IncidentStatus } from "../../../../.rest-hooks/types/incidents.yml";
import {
  ACTIVE_STATUSES,
  UNCONTAINED_STATUSES,
  OUT_STATUSES,
} from "../../../config/incidentStatus";

export type IncidentStatusOption =
  (typeof IncidentStatusOption)[keyof typeof IncidentStatusOption];

export const IncidentStatusOption = {
  all: "all",
  uncontained: "uncontained",
  out: "out",
} as const;

export const incidentStatusMap: Record<IncidentStatusOption, IncidentStatus[]> =
  {
    all: ACTIVE_STATUSES,
    uncontained: UNCONTAINED_STATUSES,
    out: OUT_STATUSES as IncidentStatus[],
  } as const satisfies Record<IncidentStatusOption, IncidentStatus[]>;

export interface IncidentsScope {
  category: IncidentCategory;
  status: IncidentStatusOption;
}

type GetIncidentsScopeParams = Pick<
  GetIncidentsParams,
  "categories" | "statuses"
>;

/**
 * Get params for use in `useGetIncidents` query from the provided scope. This
 * is helpful because the options we provide to the user diverge in type from
 * the format we need to provide them to the API in.
 */
export const getParamsFromIncidentsScope = (
  scope: IncidentsScope,
): GetIncidentsScopeParams => {
  return {
    categories: [scope.category],
    statuses: incidentStatusMap[scope.status].join(","),
  };
};

type IncidentsScopeChangeHandler = (scope: IncidentsScope) => void;

interface IncidentsScopeContextValue {
  defaultScope: IncidentsScope;
  isAdjusted: boolean;
  params: GetIncidentsScopeParams;
  onScopeChange: IncidentsScopeChangeHandler;
  scope: IncidentsScope;
}

const IncidentsScopeContext = createContext<
  IncidentsScopeContextValue | undefined
>(undefined);

interface IncidentsScopeProviderProps {
  children?: React.ReactNode;
  defaultScope: IncidentsScope;
}

/**
 * Depending on the user's context, we need to query for different incident
 * categories and statuses on different screens. Visualiser screens are wrapped
 * in an `IncidentsScopeProvider` so that multiple components can all access the
 * same scope to use for their query params. Other components (such as the
 * FilterIncidentsPopover) may update the active scope to override the top-level
 * defaults provided.
 */
export const IncidentsScopeProvider = ({
  children,
  defaultScope,
}: IncidentsScopeProviderProps) => {
  const [scope, setScope] = useState<IncidentsScope>(() => ({
    ...defaultScope,
  }));

  const onScopeChange = useCallback<IncidentsScopeChangeHandler>((newScope) => {
    setScope(newScope);
  }, []);

  const value = useMemo<IncidentsScopeContextValue>(
    () => ({
      defaultScope,
      isAdjusted: !isEqual(defaultScope, scope),
      onScopeChange,
      params: getParamsFromIncidentsScope(scope),
      scope,
    }),
    [defaultScope, onScopeChange, scope],
  );

  return (
    <IncidentsScopeContext.Provider value={value}>
      {children}
    </IncidentsScopeContext.Provider>
  );
};

export const useIncidentsScope = () => {
  const value = useContext(IncidentsScopeContext);

  if (!value) {
    throw new Error(
      "useIncidentsScope must be used inside an IncidentsScopeProvider",
    );
  }

  return value;
};

export const DEFAULT_INCIDENTS_SCOPE = {
  category: "bush-grass-all",
  status: "all",
} satisfies IncidentsScope;

export const DEFAULT_COP_INCIDENTS_SCOPE = {
  category: "any",
  status: "all",
} satisfies IncidentsScope;
