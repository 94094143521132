import { NavDrawerProvider } from "@app/design-system";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

interface AppNavDrawerProviderProps {
  children?: React.ReactNode;
}

const AppNavDrawerProvider = ({ children }: AppNavDrawerProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useRouter();

  const onOpenChange = useCallback((newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <NavDrawerProvider isOpen={isOpen} onOpenChange={onOpenChange}>
      {children}
    </NavDrawerProvider>
  );
};

export default AppNavDrawerProvider;
