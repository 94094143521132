import { Skeleton, Text, type TypographySize, media } from "@app/design-system";
import type { TextVariant } from "@app/design-system/src/components/Text/Text";
import pluralize from "pluralize";
import styled from "styled-components";
import { formatNumber } from "../../../utils/formatNumber/formatNumber";

export const StyledRecordCount = styled(Text)`
  text-align: center;

  @media ${media.lg} {
    text-align: left;
  }
`;

interface SkeletonRecordCountProps {
  size: TypographySize;
}

export const SkeletonRecordCountIndicator = ({
  size,
}: SkeletonRecordCountProps) => {
  return (
    <StyledRecordCount size={size}>
      <Skeleton width={180} />
    </StyledRecordCount>
  );
};

interface RecordCountIndicatorProps {
  isEmpty: boolean;
  page: number;
  pageLength: number;
  perPage: number;
  size: TypographySize;
  totalRecords: number;
  variant?: TextVariant;
}

const RecordCountIndicator = ({
  isEmpty,
  page,
  pageLength,
  perPage,
  size,
  totalRecords,
  variant,
}: RecordCountIndicatorProps) => {
  const pageStart = page * perPage - (perPage - 1);
  const pageEnd = pageStart - 1 + pageLength;

  return (
    <StyledRecordCount size={size} variant={variant}>
      <span>
        Showing{" "}
        <strong>
          {pageStart === pageEnd || isEmpty ? (
            pageEnd
          ) : (
            <>
              {formatNumber(pageStart)}&ndash;
              {formatNumber(pageEnd)}
            </>
          )}
        </strong>{" "}
        of <strong>{formatNumber(totalRecords)}</strong>{" "}
        {pluralize("result", totalRecords, false)}
      </span>
    </StyledRecordCount>
  );
};

export default RecordCountIndicator;
