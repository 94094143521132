import type { IncidentCategory } from "../../../../.rest-hooks/types/incidents.yml";
import type { IncidentStatusOption } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";

export const incidentCategoryLabels = {
  any: "All types",
  "bush-grass-all": "Bush & grass",
  "bush-grass-unplanned": "Unplanned bush & grass",
  "hazard-reduction": "Hazard reduction",
  other: "Other",
} as const satisfies Record<IncidentCategory, string>;

export const incidentCategoryDefault = (isCopView: boolean) =>
  isCopView ? "any" : "bush-grass-all";

export const incidentStatusDefault = "all";

export const categoryOptions = Object.entries(incidentCategoryLabels).map(
  ([value, label]) => ({
    value: value as IncidentCategory,
    label: label as string,
  }),
) satisfies { label: string; value: IncidentCategory }[];

export const statusOptions = [
  { label: "All statuses", value: "all" },
  { label: "Uncontained", value: "uncontained" },
] satisfies { label: string; value: IncidentStatusOption }[];
