import { SECOND } from "@kablamo/kerosene";

const TEN_SECONDS = 10 * SECOND;
const ONE_MINUTE = 60 * SECOND;
const FIVE_MINUTES = 5 * ONE_MINUTE;
const FIFTEEN_MINUTES = 15 * ONE_MINUTE;

export const REFETCH_INTERVAL_POLL = TEN_SECONDS;

export const REFETCH_INTERVAL_FREQUENT = FIVE_MINUTES;

export const REFETCH_INTERVAL_PERIODIC = FIFTEEN_MINUTES;
