import {
  NavDrawer,
  NavDrawerMenu,
  NavDrawerMenuItem,
  useIsMinWidth,
} from "@app/design-system";
import getConfig from "next/config";
import styled from "styled-components";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import NavLink, { getPathFromPathname } from "../../util/NavLink/NavLink";

const NavDrawerFooter = styled.div`
  display: grid;
  gap: 0.5rem;
  margin-top: auto;
`;

const StyledNSWFSLogo = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.textInverseWeak};
`;

const StyledGovLogo = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${(p) => p.theme.typography.variants.caption}
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const StyledLogos = styled.div`
  display: grid;
  padding: 0.5rem 0 0 0.5rem;
  gap: 0.25rem;
`;

const STATE_VIEW_PATHS = ["/", "/incidents", "/resources", "/coverage"];

const AppNavDrawer = () => {
  const { publicRuntimeConfig } = getConfig();
  const {
    COP_ENABLED,
    COP_PAGES_ENABLED,

    INTERCOM_APP_ID,
  } = publicRuntimeConfig;

  const { hasPrivilege: hasIncidentPrivilege } = useHasPrivilege({
    requestedPermissions: ["incident:read"],
  });
  const { hasPrivilege: hasStateMapPrivilege } = useHasPrivilege({
    requestedPermissions: ["incident:read", "prediction:read"],
  });
  const { hasPrivilege: hasSocialPrivilege } = useHasPrivilege({
    requestedPermissions: ["social:read", "social:write"],
  });
  const { hasPrivilege: hasFireModellingPrivilege } = useHasPrivilege({
    requestedPermissions: ["prediction:read", "prediction:write"],
  });
  const { hasPrivilege: hasSettingsPrivilege } = useHasPrivilege({
    requestedPermissions: [
      "notifications:settings:read",
      "notifications:settings:write",
    ],
  });

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const footerLinks = (
    <>
      {!!INTERCOM_APP_ID && (
        <NavDrawerMenuItem href="/help" target="_blank">
          Help
        </NavDrawerMenuItem>
      )}
      {hasSettingsPrivilege && (
        <NavLink
          href="/settings/notifications"
          isActive={(router) => {
            return getPathFromPathname(router.pathname).startsWith("/settings");
          }}
          legacyBehavior
          render={({ isActive }) => (
            <NavDrawerMenuItem isActive={isActive}>Settings</NavDrawerMenuItem>
          )}
        />
      )}
    </>
  );

  return (
    <NavDrawer title="Athena" data-testid="nav-drawer">
      <NavDrawerMenu>
        {hasIncidentPrivilege && (
          <>
            {hasStateMapPrivilege && (
              <NavLink
                exact
                href="/"
                isActive={(router) =>
                  STATE_VIEW_PATHS.includes(
                    getPathFromPathname(router.pathname),
                  )
                }
                legacyBehavior
                render={({ isActive }) => (
                  <NavDrawerMenuItem isActive={isActive}>
                    {COP_ENABLED ? "State View" : "State Map"}
                  </NavDrawerMenuItem>
                )}
              />
            )}
            {!COP_ENABLED && (
              <NavLink
                exact
                href="/incidents"
                legacyBehavior
                render={({ isActive }) => (
                  <NavDrawerMenuItem isActive={isActive}>
                    All incidents
                  </NavDrawerMenuItem>
                )}
              />
            )}
            {COP_ENABLED && COP_PAGES_ENABLED && (
              <NavLink
                href="/cop"
                legacyBehavior
                render={({ isActive }) => (
                  <NavDrawerMenuItem isActive={isActive}>
                    Common Operating Picture
                  </NavDrawerMenuItem>
                )}
              />
            )}
          </>
        )}
        {hasSocialPrivilege && (
          <NavLink
            href="/social"
            legacyBehavior
            render={({ isActive }) => (
              <NavDrawerMenuItem isActive={isActive}>
                Social Intel
              </NavDrawerMenuItem>
            )}
          />
        )}
        {hasFireModellingPrivilege && (
          <NavLink
            href="/fire-modelling"
            legacyBehavior
            render={({ isActive }) => (
              <NavDrawerMenuItem isActive={isActive}>
                Fire Modelling
              </NavDrawerMenuItem>
            )}
          />
        )}
        {!isTabletLandscapeAndAbove && footerLinks}
      </NavDrawerMenu>
      <NavDrawerFooter>
        <NavDrawerMenu>
          {isTabletLandscapeAndAbove && footerLinks}
        </NavDrawerMenu>
        <StyledLogos>
          <StyledNSWFSLogo>
            <img height="40" src="/logos/NSW-RFS-logo.svg" alt="NSW RFS logo" />
            NSW RURAL FIRE SERVICE
          </StyledNSWFSLogo>
          <StyledGovLogo>
            Powered by Firestory
            <img
              height="24"
              src="/logos/NSW-logo.svg"
              alt="NSW Government logo"
            />
          </StyledGovLogo>
        </StyledLogos>
      </NavDrawerFooter>
    </NavDrawer>
  );
};

export default AppNavDrawer;
