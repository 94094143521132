import { ModalOverlay } from "@app/design-system";
import SearchModalDialog from "./SearchModalDialog";
import { useSearchModal } from "./SearchModalProvider";

const SearchModal = () => {
  const { close, isOpen } = useSearchModal();

  return (
    <ModalOverlay
      isDismissable
      isOpen={isOpen}
      onClose={close}
      size="sm"
      underlayVariant="strong"
    >
      <SearchModalDialog isOpen={isOpen} onClose={close} />
    </ModalOverlay>
  );
};

export default SearchModal;
