import { createContext, useContext, useMemo } from "react";
import { useBoolean } from "usehooks-ts";

interface SearchModalContextValue {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
}

const SearchModalContext = createContext<SearchModalContextValue | undefined>(
  undefined,
);

interface SearchModalProviderProps {
  children?: React.ReactNode;
}

const SearchModalProvider = ({ children }: SearchModalProviderProps) => {
  const {
    setFalse: close,
    setTrue: open,
    toggle,
    value: isOpen,
  } = useBoolean(false);

  const searchModalProviderValue = useMemo(
    () => ({ close, open, toggle, isOpen }),
    [close, open, toggle, isOpen],
  );

  return (
    <SearchModalContext.Provider value={searchModalProviderValue}>
      {children}
    </SearchModalContext.Provider>
  );
};

export default SearchModalProvider;

export const useSearchModal = () => {
  const context = useContext(SearchModalContext);

  if (context === undefined) {
    throw new Error("useSearchModal must be used within a SearchModalProvider");
  }

  return context;
};
