import { Alert, FlexSpinner, media } from "@app/design-system";
import { useDialog } from "@react-aria/dialog";
import { useRef } from "react";
import styled from "styled-components";
import NotificationList from "./NotificationList";
import { useNotificationsUserInfinite } from "./hooks";

const StyledNotificationDialog = styled.div`
  max-height: 816px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  overflow-y: auto;
`;

const StyledOuterFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px 16px 14px;

  @media ${media.md} {
    padding: 24px 14px 16px 14px;
  }
`;

const StyledErrorContainer = styled.div`
  padding: 24px 14px 0 14px;
`;

const StyledTitle = styled.div`
  padding-left: 14px;
  ${(p) => p.theme.typography.variants.subtitleMd};
  display: none;

  @media ${media.md} {
    display: block;
  }
`;

export type NotificationDialogProps = {
  "data-testid"?: string;
  onClose: () => void;
};

const NotificationDialog = ({
  "data-testid": dataTestId,
  onClose,
}: NotificationDialogProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog({}, ref);
  const { isError, isPending } = useNotificationsUserInfinite();

  return (
    <StyledNotificationDialog
      {...dialogProps}
      ref={ref}
      data-testid={dataTestId}
    >
      <StyledOuterFlex>
        <StyledTitle>Notifications</StyledTitle>

        {isError && (
          <StyledErrorContainer>
            <Alert variant="error" title="Unable to load notification feed">
              We&apos;re having trouble loading notifications. Please check back
              again soon.
            </Alert>
          </StyledErrorContainer>
        )}
        {isPending && <FlexSpinner data-testid={`${dataTestId}-loading`} />}

        {!isError && !isPending && <NotificationList onClose={onClose} />}
      </StyledOuterFlex>
    </StyledNotificationDialog>
  );
};

export default NotificationDialog;
