import Head from "next/head";

interface HeadTitleProps {
  children?: string;
}

const HeadTitle = ({ children }: HeadTitleProps) => {
  return (
    <Head>
      <title>{children} | Athena</title>
    </Head>
  );
};

export default HeadTitle;
