import type { ColumnSort, SortingState } from "@tanstack/react-table";
import { useMemo } from "react";

interface UseSortingParams<T extends string> {
  sort?: T;
  sortingRules: Record<T, ColumnSort>;
}

export const useSorting = <T extends string>({
  sort,
  sortingRules,
}: UseSortingParams<T>): SortingState | undefined => {
  const sorting = useMemo<SortingState | undefined>(() => {
    if (!sort) return;

    const sortingRule = sortingRules[sort];
    if (!sortingRule) return;

    return [
      {
        id: sortingRule.id,
        desc: sortingRule.desc,
      },
    ];
  }, [sort, sortingRules]);

  return sorting;
};
