import {
  BookmarkFilled,
  Bookmark,
  type IconButtonSize,
  type IconButtonVariant,
  showToast,
  ToggleIconButton,
  useTheme,
} from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import {
  getGetNotificationsSubscriptionsQueryKey,
  useDeleteNotificationsIncidentId,
  usePostNotificationsIncidentId,
} from "../../../../.rest-hooks/notifications";
import useIsSubscribedToIncident, {
  MAX_NOTIFICATIONS,
} from "../../../hooks/useIsSubscribedToIncident";

interface IncidentNotificationToggleButtonProps {
  incidentId: string;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  iconOnFill?: string;
}

const IncidentNotificationToggleButton = ({
  incidentId,
  ...props
}: IncidentNotificationToggleButtonProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const {
    iconOnFill = theme.colors.primary.iconOnBackground,
    variant = "ghost",
    size = "sm",
  } = props;

  const { isSubscribedToIncident } = useIsSubscribedToIncident(incidentId);

  const { mutate: subscribeToIncident, isPending: isSubscribing } =
    usePostNotificationsIncidentId({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetNotificationsSubscriptionsQueryKey({
              page: 1,
              perPage: MAX_NOTIFICATIONS,
            }),
          });

          showToast({
            variant: "success",
            title: "Subscribed to incident",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to subscribe to incident",
          });
        },
      },
    });

  const { mutate: unsubscribeIncident, isPending: isUnsubscribing } =
    useDeleteNotificationsIncidentId({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetNotificationsSubscriptionsQueryKey({
              page: 1,
              perPage: MAX_NOTIFICATIONS,
            }),
          });

          showToast({
            variant: "success",
            title: "Unsubscribed from incident",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to unsubscribe from incident",
          });
        },
      },
    });

  const onClickIncidentSubscription = () => {
    if (isSubscribedToIncident) {
      unsubscribeIncident({
        id: incidentId,
      });
    } else {
      subscribeToIncident({
        id: incidentId,
      });
    }
  };

  return (
    <ToggleIconButton
      iconOff={Bookmark}
      iconOn={BookmarkFilled}
      label={
        isSubscribedToIncident
          ? "Unsubscribe from incident"
          : "Subscribe to incident"
      }
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClickIncidentSubscription();
      }}
      selected={isSubscribedToIncident}
      iconOnFill={iconOnFill}
      disabled={isSubscribing || isUnsubscribing}
      variant={variant}
      size={size}
      {...props}
    />
  );
};

export default IncidentNotificationToggleButton;
