import {
  ArrowDown,
  ArrowUp,
  Remove,
  StaticIconWrapper,
  Text,
} from "@app/design-system";
import styled from "styled-components";
import type { Incident } from "../../../../.rest-hooks/types";
import IncidentPriorityIndicatorError, {
  type IncidentPriorityIndicatorErrorVariant,
} from "./IncidentPriorityIndicatorError";
import IncidentPriorityTooltip from "./IncidentPriorityTooltip";

const StyledIncidentPriorityIndicator = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPriorityIncreased = styled(ArrowUp)`
  color: ${(p) => p.theme.colors.error.icon};
`;

const StyledPriorityDecreased = styled(ArrowDown)`
  color: ${(p) => p.theme.colors.warning.icon};
`;

const StyledPriorityStagnated = styled(Remove)`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

interface IncidentPriorityIndicatorProps {
  errorVariant: IncidentPriorityIndicatorErrorVariant;
  incident: Incident;
}

const IncidentPriorityIndicator = ({
  errorVariant,
  incident,
}: IncidentPriorityIndicatorProps) => {
  const {
    attributes: { currentPriority, lastPriority },
  } = incident;
  if (currentPriority === undefined) {
    return (
      <IncidentPriorityIndicatorError variant={errorVariant}>
        Priority not yet calculated
      </IncidentPriorityIndicatorError>
    );
  }

  if (currentPriority.error) {
    return (
      <IncidentPriorityIndicatorError variant={errorVariant}>
        Prioritisation error
      </IncidentPriorityIndicatorError>
    );
  }

  let icon: ReactSVGComponent;

  if (
    lastPriority === undefined ||
    currentPriority.score === lastPriority.score
  ) {
    icon = StyledPriorityStagnated;
  } else if (currentPriority.score > lastPriority.score) {
    icon = StyledPriorityIncreased;
  } else {
    icon = StyledPriorityDecreased;
  }

  const calculatedPriorityScore = Math.round(currentPriority.score * 100);

  return (
    <StyledIncidentPriorityIndicator>
      <Text size="bodyDefault">{calculatedPriorityScore}</Text>
      <StaticIconWrapper icon={icon} size="sm" />
      <IncidentPriorityTooltip riskRating={calculatedPriorityScore} />
    </StyledIncidentPriorityIndicator>
  );
};

export default IncidentPriorityIndicator;
