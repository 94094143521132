import { showToast } from "@app/design-system";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQueryClient,
  type InfiniteData,
} from "@tanstack/react-query";
import {
  type GetNotificationsUserQueryError,
  type GetNotificationsUserQueryResult,
  getGetNotificationsUserQueryKey,
  getNotificationsUser,
  usePatchNotificationsUserId,
} from "../../../../.rest-hooks/user-notifications";
import { REFETCH_INTERVAL_FREQUENT } from "../../../config/refetch";

export const useNotificationsUserInfinite = () => {
  const { data, isPending, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery<
      GetNotificationsUserQueryResult,
      GetNotificationsUserQueryError,
      InfiniteData<GetNotificationsUserQueryResult>,
      ReturnType<typeof getGetNotificationsUserQueryKey>,
      number
    >({
      getNextPageParam: (lastPage) => {
        if (!lastPage.data.links.next) return undefined;
        const url = new URL(lastPage.data.links.next);
        const pageParam = url.searchParams.get("page");
        if (!pageParam) return undefined;
        return parseInt(pageParam, 10);
      },
      placeholderData: keepPreviousData,
      initialPageParam: 1,
      queryFn: ({ pageParam }) =>
        getNotificationsUser({
          perPage: 10,
          page: pageParam,
        }),
      queryKey: getGetNotificationsUserQueryKey(),
      refetchInterval: REFETCH_INTERVAL_FREQUENT,
    });
  const notifications = data?.pages?.flatMap((page) => page.data.data) || [];
  const hasUnreadNotifications = data?.pages?.[0].data.meta.hasUnread;

  return {
    notifications,
    hasUnreadNotifications,
    isPending,
    isError,
    hasNextPage,
    fetchNextPage,
  };
};

export const useNotificationsUserMutation = () => {
  const queryClient = useQueryClient();

  const { mutate } = usePatchNotificationsUserId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetNotificationsUserQueryKey(),
        });
      },
      onError: (_, mutationData) => {
        showToast({
          id: `${mutationData.id}-read-error`,
          title: "Unable to mark notification as read",
          variant: "error",
        });
      },
    },
  });

  const onUpdateReadingStatus = (notificationId: string) => {
    mutate({
      id: notificationId,
      data: {
        hasRead: true,
      },
    });
  };

  return { onUpdateReadingStatus };
};
