import {
  AirTraffic,
  Civilian,
  DataTableHeaderIconWrapper,
  Emergency,
  EndTD,
} from "@app/design-system";
import { createColumnHelper } from "@tanstack/react-table";
import type { IncidentAttributes } from "../../../../.rest-hooks/types/incidents.yml";
import { formatIncidentStatus } from "../../../config/general";
import { formatIncidentTypes } from "../../../config/incidentTypes";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AlertLevelIcon from "../AlertLevelIcon/AlertLevelIcon";
import IncidentNotificationToggleButton from "../IncidentNotificationToggleButton/IncidentNotificationToggleButton";
import IncidentOverflowMenu from "./IncidentOverflowMenu";

export type IncidentRow = Pick<
  IncidentAttributes,
  | "alertLevel"
  | "status"
  | "name"
  | "lga"
  | "fireClass"
  | "types"
  | "burntArea"
  | "numVehicles"
  | "numAircraft"
  | "numFirefighter"
  | "updatedAt"
> & {
  id: string;
  isCopView: boolean;
};

const columnHelper = createColumnHelper<IncidentRow>();

interface GetColumnsProps {
  isCopView?: boolean;
}

export const getColumns = ({ isCopView = false }: GetColumnsProps) => {
  return [
    columnHelper.accessor("alertLevel", {
      enableSorting: true,
      header: "Alert",
      size: 80,
      sortDescFirst: true,
      cell: (props) => {
        const alertLevel = props.cell.getValue();

        return <AlertLevelIcon size="xl" alertLevel={alertLevel} />;
      },
    }),
    columnHelper.accessor("status", {
      enableSorting: true,
      header: "Status",
      size: 112,
      sortDescFirst: true,
      cell: (props) => {
        const status = props.cell.getValue();

        return formatIncidentStatus(status);
      },
    }),
    columnHelper.accessor("name", {
      enableSorting: true,
      header: "Incident name",
      size: 272,
      cell: (props) => {
        const name = props.cell.getValue();
        return name || <>&mdash;</>;
      },
    }),
    columnHelper.accessor("lga", {
      enableSorting: true,
      header: "Location",
      size: 128,
      cell: (props) => {
        const lga = props.cell.getValue();
        return lga || <>&mdash;</>;
      },
    }),
    columnHelper.accessor("fireClass", {
      enableSorting: true,
      header: "Class",
      size: 68,
      sortDescFirst: true,
      cell: (props) => {
        const fireClass = props.cell.getValue();
        return fireClass || <>&mdash;</>;
      },
    }),
    columnHelper.accessor("types", {
      enableSorting: false,
      header: "Type",
      size: 150,
      cell: (props) => {
        const types = props.cell.getValue();
        return formatIncidentTypes(types);
      },
    }),
    columnHelper.accessor("burntArea", {
      enableSorting: true,
      header: "Size",
      size: 76,
      sortDescFirst: true,
      cell: (props) => {
        const burntArea = props.cell.getValue();
        if (typeof burntArea !== "number") {
          return <>&mdash;</>;
        }
        return new Intl.NumberFormat("en-AU", {}).format(burntArea);
      },
    }),
    columnHelper.accessor("numFirefighter", {
      enableSorting: false,
      header: () => <DataTableHeaderIconWrapper icon={Civilian} />,
      size: 60,
      cell: (props) => {
        const numFirefighter = props.cell.getValue();
        if (typeof numFirefighter !== "number") {
          return <>&mdash;</>;
        }
        return new Intl.NumberFormat("en-AU", {}).format(numFirefighter);
      },
    }),
    columnHelper.accessor("numVehicles", {
      enableSorting: false,
      header: () => <DataTableHeaderIconWrapper icon={Emergency} />,
      size: 60,
      cell: (props) => {
        const numVehicles = props.cell.getValue();
        if (typeof numVehicles !== "number") {
          return <>&mdash;</>;
        }
        return new Intl.NumberFormat("en-AU", {}).format(numVehicles);
      },
    }),
    columnHelper.accessor("numAircraft", {
      enableSorting: false,
      header: () => <DataTableHeaderIconWrapper icon={AirTraffic} />,
      size: 60,
      cell: (props) => {
        const numAircraft = props.cell.getValue();
        if (typeof numAircraft !== "number") {
          return <>&mdash;</>;
        }
        return new Intl.NumberFormat("en-AU", {}).format(numAircraft);
      },
    }),
    columnHelper.accessor("updatedAt", {
      enableSorting: true,
      header: "Last updated",
      size: 108,
      sortDescFirst: true,
      cell: (props) => {
        const updatedAt = props.cell.getValue();

        return updatedAt ? (
          getFormattedDateAndTime(updatedAt * 1000)
        ) : (
          <>&mdash;</>
        );
      },
    }),
    ...(!isCopView
      ? [
          columnHelper.display({
            header: "",
            id: "actions",
            size: 104,
            cell: (props) => {
              const { id, original } = props.row;

              return (
                <EndTD>
                  <IncidentNotificationToggleButton
                    incidentId={id}
                    variant="secondary"
                  />
                  <IncidentOverflowMenu
                    incidentId={id}
                    name={original.name}
                    variant="secondary"
                  />
                </EndTD>
              );
            },
          }),
        ]
      : []),
  ];
};
