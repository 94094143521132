import { NavHeaderButton, Search } from "@app/design-system";
import { useSearchModal } from "./SearchModalProvider";

const SearchModalNavHeaderButton = () => {
  const { open } = useSearchModal();

  return <NavHeaderButton onClick={open} icon={Search} label="Search" />;
};

export default SearchModalNavHeaderButton;
