/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetPredictionsSort =
  (typeof GetPredictionsSort)[keyof typeof GetPredictionsSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPredictionsSort = {
  analystAsc: "analystAsc",
  analystDesc: "analystDesc",
  productAsc: "productAsc",
  productDesc: "productDesc",
  productTypeAsc: "productTypeAsc",
  productTypeDesc: "productTypeDesc",
  validFromAsc: "validFromAsc",
  validFromDesc: "validFromDesc",
  validToAsc: "validToAsc",
  validToDesc: "validToDesc",
} as const;
