import { ChipGroup, InputChip } from "@app/design-system";
import type { IncidentStatus } from "../../../../.rest-hooks/types/incidents.yml";
import { ACTIVE_STATUSES, OUT_STATUSES } from "../../../config/incidentStatus";

type IncidentStatusFilter = "active" | "out";

const incidentStatusFilterMap = new Map<
  IncidentStatusFilter,
  readonly IncidentStatus[]
>([
  ["active", ACTIVE_STATUSES],
  ["out", OUT_STATUSES],
]);

interface IncidentsStatusToggleProps {
  onStatusesChange: (statuses?: readonly IncidentStatus[]) => void;
  statuses: readonly IncidentStatus[];
}

const IncidentsStatusToggle = ({
  onStatusesChange,
  statuses,
}: IncidentsStatusToggleProps) => {
  const activeFilter = [...incidentStatusFilterMap.entries()].find(
    ([, filterStatuses]) =>
      statuses.every((status) => filterStatuses.includes(status)),
  );

  let value: IncidentStatusFilter | undefined;

  if (activeFilter) {
    const [activeFilterKey] = activeFilter;
    value = activeFilterKey;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: status } = event.currentTarget;
    onStatusesChange(
      incidentStatusFilterMap.get(status as IncidentStatusFilter),
    );
  };

  return (
    <ChipGroup>
      <InputChip
        checked={value === "active"}
        id="active"
        name="incidents-status-toggle"
        onChange={onChange}
        type="radio"
        value="active"
      >
        Active
      </InputChip>
      <InputChip
        checked={value === "out"}
        id="out"
        name="incidents-status-toggle"
        onChange={onChange}
        type="radio"
        value="out"
      >
        Out
      </InputChip>
    </ChipGroup>
  );
};

export default IncidentsStatusToggle;
