const QueryParams = {
  ACTIVE_LAYERS: "activeLayers",
  DISTRICT_ID: "districtId",
  HAS_BACK_STACK: "hasBackStack",
  INCIDENT_ID: "id",
  POST_ID: "post",
  PREDICTION_ID: "predictionId",
  RESOURCE_ID: "resourceId",
  SUGGESTION_ID: "suggestionId",
  TWEET_ID: "tweetId",
  FEED_ID: "feedId",
  APPLIANCE_DISPATCH_REQUESTED: "applianceDispatchRequested",
} as const;

export default QueryParams;
