const formatter = new Intl.NumberFormat("en-AU", {});

export const formatNumber = (value: number) => {
  return formatter.format(value);
};

export const formatOptionalNumber = (value: number | undefined) => {
  if (value === undefined) {
    return "—";
  }

  return formatNumber(value);
};

export const formatRoundMultiplication = (
  value: number | undefined,
  multiplicand: number,
) => {
  if (value === undefined) {
    return "—";
  }

  return formatNumber(Math.round(value * multiplicand));
};
