/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetNotificationsUser200,
  GetNotificationsUserParams,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";
import type { UserNotificationPatchBody } from "./types/notifications.yml";

/**
 * @summary Get notifications for the authorized user
 */
export const getNotificationsUser = (
  params?: GetNotificationsUserParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetNotificationsUser200>> => {
  return axios.get(`/notifications/user`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetNotificationsUserQueryKey = (
  params?: GetNotificationsUserParams,
) => {
  return [`/notifications/user`, ...(params ? [params] : [])] as const;
};

export const getGetNotificationsUserInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof getNotificationsUser>>,
    GetNotificationsUserParams["page"]
  >,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsUserParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getNotificationsUser>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getNotificationsUser>>,
        QueryKey,
        GetNotificationsUserParams["page"]
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetNotificationsUserQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNotificationsUser>>,
    QueryKey,
    GetNotificationsUserParams["page"]
  > = ({ pageParam }) =>
    getNotificationsUser(
      { ...params, page: pageParam || params?.["page"] },
      axiosOptions,
    );

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getNotificationsUser>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getNotificationsUser>>,
    QueryKey,
    GetNotificationsUserParams["page"]
  > & { queryKey: QueryKey };
};

export type GetNotificationsUserInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationsUser>>
>;
export type GetNotificationsUserInfiniteQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get notifications for the authorized user
 */
export const useGetNotificationsUserInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof getNotificationsUser>>,
    GetNotificationsUserParams["page"]
  >,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsUserParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getNotificationsUser>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getNotificationsUser>>,
        QueryKey,
        GetNotificationsUserParams["page"]
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationsUserInfiniteQueryOptions(
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetNotificationsUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationsUser>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNotificationsUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetNotificationsUserQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNotificationsUser>>
  > = () => getNotificationsUser(params, axiosOptions);

  return {
    queryKey,
    queryFn,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationsUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationsUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationsUser>>
>;
export type GetNotificationsUserQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get notifications for the authorized user
 */
export const useGetNotificationsUser = <
  TData = Awaited<ReturnType<typeof getNotificationsUser>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsUserParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNotificationsUser>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationsUserQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update authorized user's notification
 */
export const patchNotificationsUserId = (
  id: string,
  userNotificationPatchBody: UserNotificationPatchBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `/notifications/user/${id}`,
    userNotificationPatchBody,
    options,
  );
};

export const getPatchNotificationsUserIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchNotificationsUserId>>,
    TError,
    { id: string; data: UserNotificationPatchBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchNotificationsUserId>>,
  TError,
  { id: string; data: UserNotificationPatchBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchNotificationsUserId>>,
    { id: string; data: UserNotificationPatchBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchNotificationsUserId(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchNotificationsUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchNotificationsUserId>>
>;
export type PatchNotificationsUserIdMutationBody = UserNotificationPatchBody;
export type PatchNotificationsUserIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Update authorized user's notification
 */
export const usePatchNotificationsUserId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchNotificationsUserId>>,
    TError,
    { id: string; data: UserNotificationPatchBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPatchNotificationsUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
