/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetBrigadesSort =
  (typeof GetBrigadesSort)[keyof typeof GetBrigadesSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBrigadesSort = {
  nameAsc: "nameAsc",
  nameDesc: "nameDesc",
} as const;
