/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { GetPermissions200 } from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get the list of permissions
 */
export const getPermissions = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPermissions200>> => {
  return axios.get(`/permissions`, options);
};

export const getGetPermissionsQueryKey = () => {
  return [`/permissions`] as const;
};

export const getGetPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({
    signal,
  }) => getPermissions({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPermissions>>
>;
export type GetPermissionsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get the list of permissions
 */
export const useGetPermissions = <
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPermissionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
