/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetResourcesSort =
  (typeof GetResourcesSort)[keyof typeof GetResourcesSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetResourcesSort = {
  statusAsc: "statusAsc",
  statusDesc: "statusDesc",
  categoryAsc: "categoryAsc",
  categoryDesc: "categoryDesc",
  locationAsc: "locationAsc",
  locationDesc: "locationDesc",
} as const;
