/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * The category of an incident based on its type.

Allowed values are:
* `any` includes all incidents.
* `bush-grass-all` includes all incident types that Athena uses for predictions (details below).
* `bush-grass-unplanned` includes all bush and grass fire incidents (excluding hazard reductions).
* `hazard-reduction` includes planned incidents for hazard reduction.
* `other` includes all incidents not in the other defined categories (excluding "any').

`bush-grass-all` encompasses incidents with a type of:
* Bushfire
* Scrub
* Grass
* Pile burn
* Stubble burn
* Cane fire / burn off
* Fire due to lightning strike
* Tree alight
* Hazard reduction no incident

`bush-grass-unplanned` encompasses incidents with a type of:
* Bushfire
* Scrub
* Grass
* Pile burn
* Stubble burn
* Fire due to lightning strike
* Tree alight

`hazard-reduction` encompasses incidents with a type of:
* Planned Event
* Cane fire / burn off
* Hazard reduction no incident
* Hazard reduction

 */
export type IncidentCategory =
  (typeof IncidentCategory)[keyof typeof IncidentCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncidentCategory = {
  any: "any",
  "bush-grass-all": "bush-grass-all",
  "bush-grass-unplanned": "bush-grass-unplanned",
  "hazard-reduction": "hazard-reduction",
  other: "other",
} as const;
