import { StatusIcon, StatusMessage } from "@app/design-system";
import styled from "styled-components";

const StyledIncidentPriorityIndicator = styled.div`
  width: fit-content;
`;

export type IncidentPriorityIndicatorErrorVariant = "inline" | "tooltip";

interface IncidentPriorityIndicatorErrorProps {
  children?: React.ReactNode;
  variant?: IncidentPriorityIndicatorErrorVariant;
}

const IncidentPriorityIndicatorError = ({
  children,
  variant,
}: IncidentPriorityIndicatorErrorProps) => {
  if (variant === "tooltip") {
    return (
      <StyledIncidentPriorityIndicator>
        <StatusIcon size="sm" status="error" tooltip={children} />
      </StyledIncidentPriorityIndicator>
    );
  }

  return <StatusMessage variant="error">{children}</StatusMessage>;
};

export default IncidentPriorityIndicatorError;
