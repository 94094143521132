import type { ColumnSort } from "@tanstack/react-table";
import type { GetIncidentsSort } from "../../.rest-hooks/types";

export const incidentsSortingRules: Record<GetIncidentsSort, ColumnSort> = {
  alertLevelAsc: {
    id: "alertLevel",
    desc: false,
  },
  alertLevelDesc: {
    id: "alertLevel",
    desc: true,
  },
  burntAreaAsc: {
    id: "burntArea",
    desc: false,
  },
  burntAreaDesc: {
    id: "burntArea",
    desc: true,
  },
  fireClassAsc: {
    id: "fireClass",
    desc: false,
  },
  fireClassDesc: {
    id: "fireClass",
    desc: true,
  },
  lastPredictionFinishedAtAsc: {
    id: "lastPredictionFinishedAt",
    desc: false,
  },
  lastPredictionFinishedAtDesc: {
    id: "lastPredictionFinishedAt",
    desc: true,
  },
  lgaAsc: {
    id: "lga",
    desc: false,
  },
  lgaDesc: {
    id: "lga",
    desc: true,
  },
  nameAsc: {
    id: "name",
    desc: false,
  },
  nameDesc: {
    id: "name",
    desc: true,
  },
  priorityAsc: {
    id: "priority",
    desc: false,
  },
  priorityDesc: {
    id: "priority",
    desc: true,
  },
  statusAsc: {
    id: "status",
    desc: false,
  },
  statusDesc: {
    id: "status",
    desc: true,
  },
  updatedAtAsc: {
    id: "updatedAt",
    desc: false,
  },
  updatedAtDesc: {
    id: "updatedAt",
    desc: true,
  },
};
