import { FlexSpinner } from "@app/design-system";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import PagePrivilegeFallback from "../PagePrivilegeFallback/PagePrivilegeFallback";

interface PrivilegeGateProps {
  features: string[];
  render: () => React.ReactNode;
}

const PrivilegeGate = ({ features, render }: PrivilegeGateProps) => {
  const { hasPrivilege, isLoading } = useHasPrivilege({
    requestedPermissions: features,
  });

  if (isLoading) {
    return <FlexSpinner />;
  }

  if (hasPrivilege) {
    return <>{render()}</>;
  }

  return <PagePrivilegeFallback />;
};

export default PrivilegeGate;
