import { Button } from "@app/design-system";
import { useCurrentTime } from "@kablamo/kerosene-ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import styled from "styled-components";
import NotificationRow from "./NotificationRow";
import NotificationsEmptyState from "./NotificationsEmptyState";
import {
  useNotificationsUserInfinite,
  useNotificationsUserMutation,
} from "./hooks";
import { formatTimestamp, separateRecentAndOldNotifications } from "./utils";

const StyledSubtitle = styled.div`
  padding: 16px 14px 8px;
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledLoadMoreButton = styled.span`
  ${(p) => p.theme.typography.variants.bodyDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const StyledEmptyState = styled.div`
  padding: 8px 14px;
  ${(p) => p.theme.typography.variants.bodyDefault};
`;

export type NotificationListProps = {
  onClose: () => void;
};

const NotificationList = ({ onClose }: NotificationListProps) => {
  const { push } = useRouter();
  const currentTime = useCurrentTime();
  const { notifications, hasNextPage, fetchNextPage } =
    useNotificationsUserInfinite();
  const { onUpdateReadingStatus } = useNotificationsUserMutation();

  const onRedirect = (id: string, link?: string) => () => {
    onUpdateReadingStatus(id);

    if (!link) {
      onClose();
      return;
    }
    const isInternalLink = link.startsWith(window.location.origin);
    if (isInternalLink) {
      void push(link);
    } else {
      window.open(link, "_blank");
    }
    onClose();
  };

  const separatedNotifications = useMemo(
    () => separateRecentAndOldNotifications(notifications),
    [notifications],
  );

  if (!notifications?.length) {
    return <NotificationsEmptyState />;
  }

  return (
    <>
      <StyledSubtitle>Recent</StyledSubtitle>
      {!separatedNotifications.recent?.length ? (
        <StyledEmptyState>
          You have not received any notifications in the last 12 hours.
        </StyledEmptyState>
      ) : (
        <StyledFlex>
          {separatedNotifications.recent.map(
            ({ id, content, createdAt, hasRead, link }) => (
              <NotificationRow
                key={id}
                formattedTimestamp={formatTimestamp(currentTime, createdAt)}
                onNotificationClick={onRedirect(id, link)}
                onMarkAsReadClick={
                  hasRead ? undefined : () => onUpdateReadingStatus(id)
                }
              >
                {content}
              </NotificationRow>
            ),
          )}
        </StyledFlex>
      )}

      {!!separatedNotifications.earlier?.length && (
        <>
          <StyledSubtitle>Earlier</StyledSubtitle>
          <StyledFlex>
            {separatedNotifications.earlier.map(
              ({ id, content, createdAt, hasRead, link }) => (
                <NotificationRow
                  key={id}
                  formattedTimestamp={formatTimestamp(currentTime, createdAt)}
                  onNotificationClick={onRedirect(id, link)}
                  onMarkAsReadClick={
                    hasRead ? undefined : () => onUpdateReadingStatus(id)
                  }
                >
                  {content}
                </NotificationRow>
              ),
            )}
          </StyledFlex>
        </>
      )}
      {hasNextPage && (
        <Button
          variant="ghost"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => fetchNextPage()}
        >
          <StyledLoadMoreButton>Load more</StyledLoadMoreButton>
        </Button>
      )}
    </>
  );
};

export default NotificationList;
