import { StatusIcon, Text } from "@app/design-system";
import styled from "styled-components";

const StyledIncidentPriorityTooltip = styled.div`
  margin-left: 0.5rem;
`;

const StyledMessage = styled.div`
  display: grid;
  gap: 0.25rem;
`;

interface IncidentPriorityTooltipProps {
  riskRating: number;
}

const IncidentPriorityTooltip = ({
  riskRating,
}: IncidentPriorityTooltipProps) => {
  return (
    <StyledIncidentPriorityTooltip>
      <StatusIcon
        size="sm"
        status="info"
        tooltip={
          <StyledMessage>
            <Text size="subtitleSm">Risk rating: {riskRating}</Text>
            <div>
              Value indicates the level of risk associated with an incident. It
              is based on a number of factors, including: assets in the
              predicted path of fire, fire status, threat level, fire behaviour
              index.
            </div>
            <div>0 = Very low risk</div>
            <div>100 = Very high risk</div>
          </StyledMessage>
        }
        variant="weak"
      />
    </StyledIncidentPriorityTooltip>
  );
};

export default IncidentPriorityTooltip;
