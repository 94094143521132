/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  DeleteNotificationsIncidentId200,
  GetNotificationsSubscriptions200,
  GetNotificationsSubscriptionsParams,
  PostNotificationsIncidentId200,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Subscribe to incident notifications
 */
export const postNotificationsIncidentId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostNotificationsIncidentId200>> => {
  return axios.post(`/notifications/incident/${id}`, undefined, options);
};

export const getPostNotificationsIncidentIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postNotificationsIncidentId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postNotificationsIncidentId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postNotificationsIncidentId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return postNotificationsIncidentId(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostNotificationsIncidentIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postNotificationsIncidentId>>
>;

export type PostNotificationsIncidentIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Subscribe to incident notifications
 */
export const usePostNotificationsIncidentId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postNotificationsIncidentId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPostNotificationsIncidentIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Unsubscribe to incident notifications
 */
export const deleteNotificationsIncidentId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeleteNotificationsIncidentId200>> => {
  return axios.delete(`/notifications/incident/${id}`, options);
};

export const getDeleteNotificationsIncidentIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationsIncidentId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotificationsIncidentId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteNotificationsIncidentId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteNotificationsIncidentId(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationsIncidentIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotificationsIncidentId>>
>;

export type DeleteNotificationsIncidentIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Unsubscribe to incident notifications
 */
export const useDeleteNotificationsIncidentId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationsIncidentId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getDeleteNotificationsIncidentIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get list of user subscriptions
 */
export const getNotificationsSubscriptions = (
  params?: GetNotificationsSubscriptionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetNotificationsSubscriptions200>> => {
  return axios.get(`/notifications/subscriptions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetNotificationsSubscriptionsQueryKey = (
  params?: GetNotificationsSubscriptionsParams,
) => {
  return [`/notifications/subscriptions`, ...(params ? [params] : [])] as const;
};

export const getGetNotificationsSubscriptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationsSubscriptions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsSubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNotificationsSubscriptions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetNotificationsSubscriptionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNotificationsSubscriptions>>
  > = ({ signal }) =>
    getNotificationsSubscriptions(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationsSubscriptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationsSubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationsSubscriptions>>
>;
export type GetNotificationsSubscriptionsQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get list of user subscriptions
 */
export const useGetNotificationsSubscriptions = <
  TData = Awaited<ReturnType<typeof getNotificationsSubscriptions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetNotificationsSubscriptionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNotificationsSubscriptions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationsSubscriptionsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
