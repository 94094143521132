import { PlaceholderCard } from "@app/design-system";
import PageLayout from "../../layout/PageLayout/PageLayout";

const PagePrivilegeFallback = () => (
  <PageLayout variant="medium">
    <PlaceholderCard
      status="lock"
      title="Insufficient privileges"
      subtitle="Your role does not have access to this feature"
    />
  </PageLayout>
);

export default PagePrivilegeFallback;
