import {
  DEFAULT_ERROR_MESSAGE,
  DebouncedTextInput,
  PlaceholderCard,
  Search,
  useIsMinWidth,
  type PageStateChangeHandler,
  CheckboxGroupDropdownChip,
  type FormControlOption,
} from "@app/design-system";
import { keepPreviousData } from "@tanstack/react-query";
import styled from "styled-components";
import { useGetIncidents } from "../../../../.rest-hooks/incidents";
import type { GetIncidentsSort } from "../../../../.rest-hooks/types";
import type {
  IncidentCategory,
  IncidentStatus,
} from "../../../../.rest-hooks/types/incidents.yml";
import { REFETCH_INTERVAL_PERIODIC } from "../../../config/refetch";
import { incidentCategoryLabels } from "../../drawers/IncidentsDrawerCell/constants";
import { useIncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import TableLayout from "../../layout/TableLayout/TableLayout";
import IncidentsDataTable from "../IncidentsDataTable/IncidentsDataTable";
import {
  defaultIncidentSearchQuery,
  getParamsFromIncidentSearchQuery,
  type IncidentSearchQuery,
} from "../SearchResults/incidentSearchQuery";
import IncidentTile from "./IncidentTile";
import IncidentsStatusToggle from "./IncidentsStatusToggle";

export const SEARCH_RESULTS_TERM_ID = "term";

const StyledIncidentTileList = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface IncidentSearchQueryChangeHandlerOptions {
  replace?: boolean;
}

export type IncidentSearchQueryChangeHandler = (
  searchQuery: IncidentSearchQuery,
  options?: IncidentSearchQueryChangeHandlerOptions,
) => void;

interface IncidentsListProps {
  isCopView?: boolean;
  onSearchQueryChange: IncidentSearchQueryChangeHandler;
  searchQuery: IncidentSearchQuery;
}

const IncidentsList = ({
  isCopView = false,
  onSearchQueryChange,
  searchQuery,
}: IncidentsListProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const params = getParamsFromIncidentSearchQuery(searchQuery);
  const { params: scopeParams } = useIncidentsScope();

  // We have different defaults for COP vs State View incidents, so we handle empty arrays differently
  const defaultCategories =
    scopeParams.categories ?? defaultIncidentSearchQuery.categories;

  const { data, isError, isFetching, isPending } = useGetIncidents(
    {
      ...scopeParams,
      ...params,
      categories: params.categories?.length
        ? params.categories
        : defaultCategories,
    },
    {
      query: {
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
      },
    },
  );

  const { categories, page, perPage, statuses, sort } = searchQuery;

  const incidents = data?.data.data ?? [];
  const meta = data?.data.meta;

  const onTermChange = (value: string) => {
    onSearchQueryChange(
      {
        ...searchQuery,
        term: value,
      },
      { replace: true },
    );
  };

  const onCategoryChange = (value?: IncidentCategory[]) =>
    onSearchQueryChange({
      ...searchQuery,
      categories: value ?? defaultCategories,
    });

  const onSortChange = (value?: GetIncidentsSort) => {
    onSearchQueryChange({
      ...searchQuery,
      sort: value ?? defaultIncidentSearchQuery.sort,
    });
  };

  const onStatusChange = (values?: readonly IncidentStatus[]) => {
    onSearchQueryChange({
      ...searchQuery,
      statuses: values ?? defaultIncidentSearchQuery.statuses,
    });
  };

  let content: React.ReactNode;

  if (!isTabletLandscapeAndAbove) {
    content = (
      <StyledIncidentTileList>
        {incidents.map((incident) => (
          <IncidentTile
            key={incident.id}
            isCopView={isCopView}
            incident={incident}
          />
        ))}
      </StyledIncidentTileList>
    );
  } else {
    content = (
      <IncidentsDataTable
        isCopView={isCopView}
        incidents={incidents}
        onSortChange={onSortChange}
        page={page}
        perPage={perPage}
        sort={sort}
      />
    );
  }

  const onPageStateChange: PageStateChangeHandler = (pageState) => {
    onSearchQueryChange({
      ...searchQuery,
      ...pageState,
    });
  };

  let emptyState: React.ReactNode;
  if (searchQuery.term) {
    emptyState = (
      <PlaceholderCard
        title="No results found"
        subtitle={`No incidents matching term "${searchQuery.term}" were found`}
        status="empty"
      />
    );
  } else {
    emptyState = (
      <PlaceholderCard
        title="No incidents found"
        subtitle="There are no active incidents"
        status="empty"
      />
    );
  }

  return (
    <TableLayout
      chips={
        <>
          <IncidentsStatusToggle
            onStatusesChange={onStatusChange}
            statuses={statuses}
          />
          {isCopView && (
            <CheckboxGroupDropdownChip
              items={Object.entries(incidentCategoryLabels).map(
                ([value, label]) =>
                  ({ label, value }) as FormControlOption<IncidentCategory>,
              )}
              itemType="category"
              label="Categories"
              onChange={onCategoryChange}
              value={categories}
              data-testid="category-filter"
            />
          )}
        </>
      }
      emptyState={emptyState}
      errorState={
        <PlaceholderCard
          title="Unable to load incidents"
          subtitle={DEFAULT_ERROR_MESSAGE}
          status="error"
        />
      }
      isError={isError}
      isFetching={isFetching}
      isLoading={isPending}
      meta={meta}
      onPageStateChange={onPageStateChange}
      page={page}
      pageLength={incidents.length}
      perPage={perPage}
      search={
        <DebouncedTextInput
          iconStart={Search}
          id={SEARCH_RESULTS_TERM_ID}
          isClearable
          onChange={onTermChange}
          placeholder="Search name or LGA..."
          size="sm"
          value={searchQuery.term}
        />
      }
      title="Incidents"
    >
      {content}
    </TableLayout>
  );
};

export default IncidentsList;
