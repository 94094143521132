import AppNavDrawer from "../../ui/AppNavDrawer/AppNavDrawer";
import AppNavDrawerProvider from "../../ui/AppNavDrawer/AppNavDrawerProvider";
import SearchModal from "../../ui/SearchModal/SearchModal";
import SearchModalProvider from "../../ui/SearchModal/SearchModalProvider";

interface RootLayoutProps {
  children?: React.ReactNode;
}

const RootLayout = ({ children }: RootLayoutProps) => {
  return (
    <AppNavDrawerProvider>
      <SearchModalProvider>
        <>
          <AppNavDrawer />
          <SearchModal />
          {children}
        </>
      </SearchModalProvider>
    </AppNavDrawerProvider>
  );
};

export default RootLayout;
