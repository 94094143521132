/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetCoverageDistrictsSort =
  (typeof GetCoverageDistrictsSort)[keyof typeof GetCoverageDistrictsSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCoverageDistrictsSort = {
  priorityAsc: "priorityAsc",
  priorityDesc: "priorityDesc",
  districtNameAsc: "districtNameAsc",
  districtNameDesc: "districtNameDesc",
  habitablePropertiesTotalCountAsc: "habitablePropertiesTotalCountAsc",
  habitablePropertiesTotalCountDesc: "habitablePropertiesTotalCountDesc",
  criticalInfrastructureTotalCountAsc: "criticalInfrastructureTotalCountAsc",
  criticalInfrastructureTotalCountDesc: "criticalInfrastructureTotalCountDesc",
} as const;
