import { useGetNotificationsSubscriptions } from "../../.rest-hooks/notifications";

export const MAX_NOTIFICATIONS = 5000;

const useIsSubscribedToIncident = (incidentId: string | undefined) => {
  const { data } = useGetNotificationsSubscriptions({
    page: 1,
    perPage: MAX_NOTIFICATIONS,
  });
  const subscriptions = data?.data.data;
  const isSubscribedToIncident = subscriptions?.some(
    ({ attributes }) => attributes?.incidentId === incidentId,
  );

  return {
    isSubscribedToIncident,
  };
};

export default useIsSubscribedToIncident;
