import { Empty, Text } from "@app/design-system";
import styled from "styled-components";

const StyledEmptyState = styled.div`
  padding: 24px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  text-align: center;
`;

const NotificationsEmptyState = () => {
  return (
    <StyledEmptyState>
      <Empty width="120px" data-testid="notifications-empty-image" />
      <Text size="bodyDefault">You have no notifications.</Text>
    </StyledEmptyState>
  );
};
export default NotificationsEmptyState;
