import { Text, buttonReset, weakStyles } from "@app/design-system";
import styled from "styled-components";
import UnreadIndicator from "./UnreadIndicator";

const StyledNotificationRow = styled.button`
  ${buttonReset}
  padding: 8px 14px;
  display: grid;
  grid-template-columns: auto 20px;
  column-gap: 8px;
  align-items: flex-start;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  cursor: pointer;

  &:hover {
    ${weakStyles.background.hover}
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const StyledText = styled.div<{ hasRead: boolean }>`
  ${(p) => p.theme.typography.variants.bodyDefault};
  font-weight: ${(p) =>
    p.hasRead ? p.theme.fontWeights.regular : p.theme.fontWeights.semibold};
`;

export type NotificationRowProps = {
  children: React.ReactNode;
  "data-testid"?: string;
  /** ISO8601 timestamp */
  formattedTimestamp: string;
  onNotificationClick: () => void;
  onMarkAsReadClick?: () => void;
};

const NotificationRow = ({
  children,
  "data-testid": dataTestId,
  formattedTimestamp,
  onNotificationClick,
  onMarkAsReadClick,
}: NotificationRowProps) => {
  const handleNotificationClick = () => {
    onMarkAsReadClick?.();
    onNotificationClick();
  };

  return (
    <StyledNotificationRow
      onClick={handleNotificationClick}
      data-testid={dataTestId}
    >
      <StyledTextContainer>
        <StyledText hasRead={!onMarkAsReadClick}>{children}</StyledText>
        <Text size="footnote" variant="weak">
          {formattedTimestamp}
        </Text>
      </StyledTextContainer>

      {onMarkAsReadClick && <UnreadIndicator onClick={onMarkAsReadClick} />}
    </StyledNotificationRow>
  );
};

export default NotificationRow;
