import { useGetPermissions } from "../../.rest-hooks/permissions";

interface UseHasPrivilegeParams {
  requestedPermissions: string[];
}

export const useHasPrivilege = ({
  requestedPermissions,
}: UseHasPrivilegeParams) => {
  const { data, isPending } = useGetPermissions();
  const userPermissions = data?.data.meta.permissions;

  return {
    isLoading: !data && isPending,
    hasPrivilege: requestedPermissions.every(
      (permission) => userPermissions?.includes(permission),
    ),
  };
};
