import groupBy from "lodash/groupBy";
import type { UserNotification } from "../../../../.rest-hooks/types/notifications.yml";
import {
  getFormattedDateAndTime,
  getTimePassedShort,
  isRecent,
} from "../../../utils/formatDate/formatDate";

export const separateRecentAndOldNotifications = (
  notifications: readonly UserNotification[],
): {
  recent: UserNotification[] | undefined;
  earlier: UserNotification[] | undefined;
} => {
  return groupBy(notifications, ({ createdAt }) =>
    isRecent(new Date().toISOString(), createdAt) ? "recent" : "earlier",
  ) as {
    recent: UserNotification[] | undefined;
    earlier: UserNotification[] | undefined;
  };
};

export const formatTimestamp = (
  referenceTime: string | number,
  isoString: string,
) => {
  return isRecent(referenceTime, isoString)
    ? getTimePassedShort(referenceTime, isoString)
    : getFormattedDateAndTime(new Date(isoString).getTime());
};
